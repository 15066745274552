import React from 'react'
import { inject, observer } from 'mobx-react'
import styled, { ThemeProvider } from 'styled-components'
import { buildTheme, buildGlobalStyle } from '/styles/theme'

const Background = styled.div`
  ${(props) => props.theme.colors.backgroundCss}
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
`

const ContentWrapper = styled.div`
  align-items: center;
  background-color: #fff;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 16px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 600px;
  min-height: 500px;
  min-width: 400px;
  padding: 50px;
  box-shadow: 2px 2px 16px 2px rgb(0 0 0 / 50%);

  ${(props) => props.theme.media.phone`
    padding: 0;
    margin: 0 5px;
  `}
`

const H1 = styled.h1`
  font-size: 3rem;
`

const LogoWrapper = styled.div`
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
`

const LogoImg = styled.img`
  width: 90% !important;
  max-width: 300px !important;
  height: auto !important;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
`

const EmptyLayout = inject('store')(
  observer(({ siteData, showLogo = true, children }) => {
    const theme = buildTheme(siteData)
    const GlobalStyle = buildGlobalStyle(siteData)
    const renderTitle = () => {
      if (siteData.logo_media_center?.media) {
        return (
          <LogoImg
            alt=""
            className="img-responsive"
            src={siteData.logo_media_center?.media}
          />
        )
      } else {
        return <H1 className="text-center">{siteData.name}</H1>
      }
    }
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Background>
          <ContentWrapper>
            {showLogo && <LogoWrapper>{renderTitle()}</LogoWrapper>}
            {children}
          </ContentWrapper>
        </Background>
      </ThemeProvider>
    )
  })
)

export default EmptyLayout
