import React from 'react'
import Link from 'next/link'
import { inject, observer } from 'mobx-react'

import { PAGEURL } from '/constants'
import PageTitle from '/components/common/PageTitle'
import EmptyLayout from '/components/layouts/EmptyLayout'

const Unavailable = inject('store')(
  observer(({ store }) => {
    const { siteData } = store
    const title = `${siteData.get('name')} - Unavailable`
    const renderMessage = () => {
      return (
        <div className="text-center message">
          <p>This site is unavailable at the moment. Please try back later.</p>
          <p>
            If you are the owner you can{' '}
            <Link href={PAGEURL.LOGIN}>
              <a>login</a>
            </Link>{' '}
            and administer the site.
          </p>
        </div>
      )
    }
    return (
      <div>
        <PageTitle title={title} />
        <div className="container">{renderMessage()}</div>
      </div>
    )
  })
)

Unavailable.Layout = EmptyLayout

export default Unavailable
